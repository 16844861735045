import React, { useEffect } from "react";
import "styles/App.css";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { logPageView } from "routes/gaTracker";
import ErrorPage from "components/Status/Error";
import { useAuth } from "contexts/auth-context";
import Loading from "components/Status/Loading.js";
import { ErrorBoundary } from "react-error-boundary";
const UnAuthApp = React.lazy(() => import("containers/Auth/Authentication"));
const AuthenticatedApp = React.lazy(() => import("./App_authenticated"));

import { useAddErrorPage } from "api/errorpages";

const useStyles = makeStyles((theme) => ({
	layout: {
		boxSizing: "border-box",
		display: "flex",
		flex: "auto",
		flexDirection: "column",
		minHeight: 0,
		background: "#f0f2f5",
		// "& *": {
		// 	boxSizing: "border-box",
		// },
	},
}));

function App() {
	const classes = useStyles();
	const history = useHistory();
	const { user } = useAuth();
	const [submit, createStatus] = useAddErrorPage();
	useEffect(() => {
		logPageView(history);
	}, [history]);

	return (
		<div className={classes.layout}>
			<ErrorBoundary
				fallback={<ErrorPage />}
				onError={(error) => {
					const { message, stack } = error;
					// log the error
					console.log("Error caught!");
					console.error(message, 'error');
					console.error(stack, 'info');

					const url = window.location.href;
					let e = { errorPageData: message, errorPageStack: stack, errorPageUrl: url, date: new Date() };

					submit(e);

					// record the error in an APM tool...
				}}
			>
				<React.Suspense fallback={<Loading />}>
					{user ? <AuthenticatedApp /> : <UnAuthApp />}
				</React.Suspense>
			</ErrorBoundary>
		</div>
	);
}

export default App;
